<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="label_element_cover">
      <label>Date</label>
      <flat-pickr
        v-model="filter.date"
        :config="configDate"
        placeholder="Select Date"
        name="birthdate"
      />
      </div>
      <div class="label_element_cover">
      <label>Service</label>
      <input type="text" v-model="filter.service" disabled />
      </div>
      <div class="bt_generate_label_cover">
      <input type="button" value="Submit" @click="fetchSlips" />
      </div>
    </div>
    <loader v-if="loading" />
    <div v-if="!loading && Object.keys(list).length > 0" class="for_print_adj">
      <div class="default_container">
        <div v-for="(couriers,key,i) in list" :key="'slips'+i" class="page_break_top">
          <section>
            <div class="form_section_transmit">
              <table class="transmit_table1">
                <tr>
                  <td>{{user.branch_code}}</td>
                  <td>SUMMARY TRANSMIT SLIP</td>
                  <td>
                    <img src="../../assets/temp_logo1_new.png" />
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <section>
            <div class="form_section_transmit">
              <table class="transmit_table2">
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td>{{user.division}}</td>
                  <td>
                    <div class="dispatch_date">
                      Dispatch date:
                      <span>{{moment(filter.date).format("MM-DD-YYYY")}}</span>
                    </div>
                    <barcode :value="key" tag="img" />
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <section>
            <div class="form_section_transmit">
              <table class="transmit_table3">
                <tr>
                  <td>REF</td>
                  <td>ADDRESSEE</td>
                  <td>ITEM</td>
                  <td>ITEM DESCRIPTION</td>
                  <td>SENDER</td>
                </tr>
                <tr v-for="(data,j) in couriers" :key="'courier'+i+j">
                  <td>
                    <barcode :value="data.booking_prefix + data.booking_id" tag="img" />
                  </td>
                  <td>
                    <p v-if="data.delivery_name">{{data.delivery_name}}</p>
                    <p v-if="data.delivery_address_1">{{data.delivery_address_1}}</p>
                    <p v-if="data.delivery_address_2">{{data.delivery_address_2}}</p>
                    <p v-if="data.delivery_city">{{data.delivery_city}}</p>
                    <p v-if="data.delivery_state">{{data.delivery_state}}</p>
                    <p v-if="data.delivery_country">{{data.delivery_country}}</p>
                  </td>
                  <td>{{data.item_qty}}</td>
                  <td>{{data.goods_description || 'NA'}}</td>
                  <td>{{data.pickup_name}}</td>
                </tr>
              </table>
            </div>
          </section>
          <div class="break_avoider">
            <h3>{{couriers.length}} Total Pcs</h3>
            <section>
              <div class="form_section_transmit">
                <table class="transmit_table4">
                  <tr>
                    <td>
                      <div class="small_text">Prepared by(Branch Representative)</div>
                      <div class="big_text">{{user.name}}</div>
                      <br />Signature over Printed Name
                    </td>
                    <td>
                      <div class="big_text">{{moment(filter.date).format("MM/DD/YYYY")}}</div>Date
                    </td>
                    <td>
                      <div class="small_text">Checked By: (Branch Personal)</div>
                      <hr />
                      <div class="small_text">Signature over Printed Name</div>
                    </td>
                    <td>
                      <div class="big_text">{{moment(filter.date).format("MM/DD/YYYY")}}</div>Date
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="small_text">Acknowledged By: (Recipient)</div>

                      <br />
                      <hr />Signature over Printed Name
                    </td>
                    <td>
                      <div class="big_text">{{moment(filter.date).format("MM/DD/YYYY")}}</div>
                      <hr />Date
                    </td>
                    <td></td>
                    <td>
                      <div class="big_text">{{moment(Date.now()).format("HH:mm:ss")}}</div>Time
                    </td>
                  </tr>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section class="centered">
        <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">Cancel</a>
        <a class="link_bt bt_cancel" @click="printTransmittalSlip">Print</a>
        <a
          class="link_bt bt_save"
          @click="dispatchSlip"
          :disabled="dispatch_btn.disabled"
        >{{dispatch_btn.value}}</a>
      </section>
    </div>
    <h3 v-if="!loading && Object.keys(list).length == 0">No data found</h3>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "x",
  components: {
    flatPickr
  },
  created() {
    this.$emit("breadCrumbData", ["Dispatch", "View Transmittal Slip"]);
    this.filter.date = this.moment(new Date(Date.now())).format("YYYY-MM-DD");
    this.fetchUser();
    this.fetchSlips();
  },
  data() {
    return {
      loading: true,
      enableTime: true,
      configDate: {
        altInput: true,
        noCalendar: false,
         format: "d-m-Y",
          altFormat: "d-m-Y",
      },
      filter: {
        date: "",
        service: "Messenger"
      },
      list: {},
      user: {
        name: "",
        branch_code: "",
        division: ""
      },
      loading: true,
      dispatch_btn: {
        disabled: false,
        value: "PRINT AND DISPATCH"
      }
    };
  },
  methods: {
    fetchUser() {
      this.axios
        .get("/api/user/data")
        .then(response => {
          this.user.name = response.data.msg.name;
          this.user.branch_code = response.data.msg.branch.branch_id;
          this.user.division = response.data.msg.division.name;
        })
        .catch(error => {
          this.toast.error(error);
        });
    },
    fetchSlips() {
      this.loading = true;
      this.axios
        .post("/api/slip/generated", this.filter)
        .then(response => {
          console.log(response);
          this.list = response.data.list;
          this.loading = false;
        })
        .catch(error => {
          console.log(e);
          this.loading = false;
        });
    },
    dispatchSlip() {
      this.dispatch_btn.disabled = true;
      this.dispatch_btn.value = "DISPATCHING...PLEASE WAIT";
      this.axios
        .post("api/slip/dispatch", this.filter)
        .then(response => {
          console.log(response);
          this.toast.success(response.data.msg);
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.value = "PRINT AND DISPATCH";
          this.$router.push("/pages/CreateDispatchDeliveryNote");
        })
        .catch(error => {
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.value = "PRINT AND DISPATCH";
          console.log(error);
        });
    },
    printTransmittalSlip() {
      window.print();
    }
  }
};
</script>
<style>
.transmit_table2 tr td img,
.transmit_table3 tr td img {
  height: 110px !important;
  width: 100%;
}
@media print {
  .break_avoider {
    page-break-inside: avoid;
  }
  .page_break_top {
    page-break-before: always;
  }
  .default_container .page_break_top:first-child {
    page-break-before: auto;
  }
  .page_break_bottom {
    page-break-after: auto;
  }
  .transmit_table3 tr:nth-child(12n + 12) td {
    border-bottom: 2px solid #999 !important;
    page-break-after: always;
  }

  .transmit_table3 tr:nth-child(12n + 12) {
    page-break-after: always;
  }
  .wrapper {
    display: block;
  }
  .main {
    display: block !important;
  }

  @page {
    size: portrait;
    margin-top: 10px;
  }
  body,
  html {
    height: auto !important;
  }
  .sidebar {
    display: none;
  }
  .main_panel {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .for_print_adj {
    padding: 0 20px;
  }
  .main_panel > header {
    display: none !important;
  }
  .main_panel .under_header {
    display: none;
  }
  .main_panel .transmittal_slip_filter {
    display: none;
  }
  .main_section {
    margin-top: 0 !important;
  }
  .transmit_sub_container {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .transmit_table2 tr td:nth-child(2) {
    font-size: 18px;
  }
  .transmit_table2 tr td:first-child {
    font-size: 18px !important;
  }

  .form_section_transmit {
    margin: 0 !important;
  }
  .transmit_table1 tr td {
    padding: 2 10px;
    font-size: 12px;
  }
  .transmit_table3 tr td .bar_text {
    font-size: 10px;
  }
  .transmit_table1 tr td:nth-child(2) {
    font-size: 16px;
  }
  .transmit_table3 tr:first-child td {
    font-size: 12px;
  }
  .transmit_table3 tr td {
    padding: 2px 5px;
  }
  .transmit_table4 tr td {
    padding: 2px 5px;
  }
  .transmit_table4 tr td .big_text {
    font-size: 12px;
  }
  .transmit_table4 tr td {
    font-size: 12px;
  }
  .transmit_table3 tr td {
    font-size: 12px;
  }
  .transmit_table3 tr td p {
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
  }
  .transmit_table3 tr td p::after {
    content: ",";
  }
  .transmit_table3 tr td p:last-child::after {
    content: "";
  }
  section.centered {
    display: none !important;
  }
  .transmit_sub_container h3 + span {
    page-break-after: always;
  }
}
</style>

